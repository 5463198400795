<template>
    <div id="scheduled-messages-sent">
        <div class="loading-active-conversation-box" v-if="loading">
            <span class="spinner spinner-border text-primary"></span>
        </div>
        <div class="d-flex flex-column align-items-stretch" v-else>
            <table class="table table-borderless template-table">
                <thead class="table-header d-flex align-items-start">
                    <th scope="col" class="text-left text-dark-black date-time-content">
                        {{ $t('sent-date') }}
                    </th>
                    <th scope="col" class="text-left text-dark-black message-content flash">
                        Message
                    </th>
                    <th scope="col" class="text-left text-dark-black tags-content flash">{{ $tc('recipient', 2) }}</th>
                    <th scope="col" class="text-left text-dark-black edit-scheduled-message">&nbsp;</th>
                </thead>
                <tbody class="table-content" :class="{'empty-scheduled-send': Object.keys(flashMessagesSent).length === 0}">
                    <preloader-placeholder :type="'table'"></preloader-placeholder>
                    <tr class="mb-3" v-for="message in flashSent" :key="message.id">
                        <td scope="col" class="text-left font-color-content date-time-content">
                            <p>
                                <span>{{ toUtc(message.attributes.sentAt).date }}</span>
                                <span class="time"> {{ toUtc(message.attributes.sentAt).time }} <utc>UTC</utc></span>
                            </p>
                        </td>
                        <td scope="col" class="text-left font-color-content message-content text-ellipsis flash">
                            <span>{{ message.attributes.content }}</span>
                        </td>
                        <td scope="col" class="text-left font-color-content tags-content flash">
                            <div v-if="message.attributes.audiences == null">
                                <p>{{ $t('all') }}</p>
                                <div class="text-left contact-number">
                                    <span>({{ message.attributes.audienceCount }} contacts)</span>
                                </div>
                            </div>
                            <div v-else>
                                <div v-for="contact in message.attributes.contacts" :key="contact.id" class="d-flex d-inline-flex flex-row p-0 mr-2 tags flash">
                                    <p>{{ contact.name !== '' ? contact.name : contact.phoneNumber }}</p>
                                </div>
                                <div class="text-left contact-number">
                                    <span>({{ message.attributes.audienceCount }} contacts)</span>
                                </div>
                            </div>
                        </td>
                        <td scope="col" class="edit-scheduled-message buttons-content">
                            <button class="border-0 bg-white mr-3" data-target="#flash-send-sidebar"
                                @click="showFlashMessageDetails(message.id)">
                                <font-awesome-icon class="text-grey" :icon="['fa', 'eye']" />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <b-pagination
                v-model="page.number"
                :total-rows="flashScheduledMeta.page.total"
                :per-page="flashScheduledMeta.page['per-page']"
                @input="loadFlashMessages"
                align="center"
            ></b-pagination>
        </div>
        <send-modal :displayMessageDetail="true"></send-modal>
    </div>
</template>

<script>

import SendModal            from './SendModal.vue';
import PreloaderPlaceholder from '@/core/components/PreloaderPlaceholder.vue';
import Utc                  from '@/core/components/UTC.vue';
import Format               from '@/helpers/Format.js';

// Import external libraries
import { mapState, mapActions } from 'vuex';

export default {
    components: {
        SendModal,
        PreloaderPlaceholder,
        Utc
    },
    data () {
        const FLASH_CATEGORY = 'flash';

        return {
            FLASH_CATEGORY,
            flashSent: [],
            loading:   false,
            page:      {
                number: 1,
                size:   25
            },
        };
    },

    computed: {
        ...mapState('core/scheduled-messages', {
            'flashMessagesSent':  'sentScheduledMessages',
            'flashScheduledMeta': 'sentScheduledMeta'
        }),

        ...mapState('core/contacts', [
            'contacts'
        ]),

        /**
         * SMS contact list
         */
        allContacts () {
            let data = [];

            Object.values(this.contacts).forEach(element => {
                const name = (element.attributes['first-name'] !== null && element.attributes['last-name'] !== null) ?
                    `${element.attributes['first-name']} ${element.attributes['last-name']}` : '';

                data.push({
                    name,
                    id:          element.id,
                    phoneNumber: element.attributes['phone-number'],
                });
            });

            return data;
        }
    },

    mounted () {
        this.loadFlashMessages();
    },

    methods: {
        ...mapActions('core/scheduled-messages', {
            index:              'indexSent',
            selectFlashMessage: 'select'
        }),

        /**
         * Load planned on no_planned flash messages
         */
        loadFlashMessages () {
            this.loading = true;

            // Get list of Flash all messages
            this.index({
                'category': this.FLASH_CATEGORY,
                page:       this.page
            })
                .then(() => {
                    this.flashSent = [];
                    Object.values(this.flashMessagesSent).forEach(message => {
                        message.attributes.contacts = [];
                        message.attributes.audiences.forEach(phoneNumber => {
                            message.attributes.contacts.push(this.allContacts.find(contact => contact.phoneNumber === phoneNumber));
                        });
                        this.flashSent.push(message);
                    });

                    // Finish loading
                    this.loading = false;
                });
        },

        /**
         * Open the flash message selected detail modal
         */
        showFlashMessageDetails (flashMessageId) {
            this.selectFlashMessage({ id: flashMessageId, status: 'sent' })
                .then(() => {
                    this.$bvModal.show('flash-send-sidebar');
                });
        },

        /**
         * Helper method to format date to use in template
         */
        toUtc: Format.toUtc
    }
};
</script>
