<template>
    <div id="scheduled-messages">
        <div class="d-flex justify-content-end mb-4">
            <button
                class="btn btn-primary rounded-pill px-4 add-btn"
                data-target="#flash-send-sidebar"
                :disabled="!sms.isEstablished"
                @click="addFlashMessage()">
                {{ $t('add-flash-send') }}
            </button>
        </div>
        <div class="loading-active-conversation-box" v-if="loading">
            <span class="spinner spinner-border text-primary"></span>
        </div>
        <div class="d-flex flex-column align-items-stretch" v-else>
            <table class="table table-borderless m-0 template-table">
                <thead class="table-header d-flex align-items-start">
                    <th scope="col" class="text-left text-dark-black message-content mr-auto flash">Messages</th>
                    <th scope="col" class="text-left text-dark-black tags-content flash">{{ $tc('recipient', 2) }}</th>
                    <th scope="col" class="text-left text-dark-black date-time-content">{{ $t('schedule-diffusion') }}</th>
                    <th scope="col" class="text-left text-dark-black edit-scheduled-message">&nbsp;</th>
                </thead>
                <tbody class="table-content mb-3">
                    <preloader-placeholder :type="'table'"></preloader-placeholder>
                    <tr v-for="message in flashPlanned" :key="message.id"  :class="{'empty-scheduled-send':Object.keys(flashMessagesPlanned).length === 0}">
                        <td scope="col" class="text-left font-color-content message-content text-ellipsis flash">
                            <span> {{ message.attributes.content }} </span>
                        </td>
                        <td scope="col" class="text-left font-color-content tags-content flash">
                            <div v-if="message.attributes.audiences === null || message.attributes.audiences.length === 0">
                                <span>{{ $t('all') }}</span>
                            </div>
                            <div v-else>
                                <div class="d-flex d-inline-flex flex-row p-0 mr-2 tags flash"
                                    v-for="contact in message.attributes.contacts" :key="contact.id">
                                    <p>{{ contact.name !== '' ? contact.name : contact.phoneNumber }}</p>
                                </div>
                            </div>
                        </td>
                        <td scope="col" class="text-left font-color-content date-time-content">
                            <div v-if="message.attributes.plannedDate || message.attributes.plannedTime">
                                <div><span>{{ message.attributes.plannedDate }}</span></div>
                                <div><span>{{ message.attributes.plannedTime }} <utc>UTC</utc></span></div>
                            </div>
                            <div v-else>
                                <p>---</p>
                            </div>
                        </td>
                        <td scope="col" class="edit-scheduled-message buttons-content ml-3">
                            <button class="border-0 bg-white mr-3 single-message-btns"
                                @click="sendFlashMessage(message.id)">
                                <font-awesome-icon class="text-dark-pink" :icon="['fas', 'paper-plane']"/>
                            </button>
                            <button class="border-0 bg-white mr-3 single-message-btns"
                                @click="editFlashMessage(message)">
                                <font-awesome-icon class="text-grey" :icon="['fas', 'pencil-alt']"/>
                            </button>
                            <button class="border-0 bg-white single-message-btns"
                                @click="deleteFlashMessage(message.id)">
                                <font-awesome-icon class="text-grey" :icon="['fas', 'times']" />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <b-pagination
                v-model="page.number"
                :total-rows="flashScheduledMeta.page.total"
                :per-page="flashScheduledMeta.page['per-page']"
                @input="loadFlashMessages"
                align="center"
            ></b-pagination>
        </div>
        <send-modal
            :editMessage="editMessage"
            @flashListUpdated="loadFlashMessages()"
        >
        </send-modal>
    </div>
</template>

<script>

// Internal repository
import SendModal            from './SendModal.vue';
import Alert                from '@/helpers/Alert.js';
import PreloaderPlaceholder from '@/core/components/PreloaderPlaceholder.vue';
import Utc                  from '@/core/components/UTC.vue';

// Import external libraries
import { mapActions, mapState } from 'vuex';

export default {
    components: {
        SendModal,
        PreloaderPlaceholder,
        Utc
    },
    data () {
        const FLASH_CATEGORY = 'flash';

        return {
            FLASH_CATEGORY,
            flashPlanned: [],
            loading:      false,
            editMessage:  false,
            page:         {
                number: 1,
                size:   25
            },
        };
    },

    computed: {
        ...mapState('core/scheduled-messages', {
            'flashMessagesPlanned': 'plannedScheduledMessages',
            'flashScheduledMeta':   'plannedScheduledMeta'
        }),

        ...mapState('core/connectors', [
            'sms'
        ]),

        ...mapState('core/contacts', [
            'contacts'
        ]),

        /**
         * SMS contact list
         */
        allContacts () {
            let data = [];

            Object.values(this.contacts).forEach(element => {
                const name = (element.attributes['first-name'] !== null && element.attributes['last-name'] !== null) ?
                    `${element.attributes['first-name']} ${element.attributes['last-name']}` : '';

                data.push({
                    name,
                    id:          element.id,
                    phoneNumber: element.attributes['phone-number'],
                });
            });

            return data;
        }
    },

    mounted () {
        this.loadFlashMessages();

        this.addFlashMessage();
    },

    methods: {
        ...mapActions('core/scheduled-messages', {
            delete:                    'delete',
            indexPlanned:              'indexPlanned',
            indexSent:                 'indexSent',
            selectFlashMessage:        'select',
            send:                      'send',
            resetSelectedFlashMessage: 'resetSelectedScheduledMessage'
        }),

        ...mapActions('core/contacts', [
            'index'
        ]),

        /**
         * Load planned on no_planned flash messages
         */
        loadFlashMessages () {
            this.loading = true;

            // Get all sms contacts
            this.index({
                filters: {
                    'sms-contact': true,
                }
            })
                .then(() => {
                    // Get list of flash planned messages
                    this.indexPlanned({
                        'category': this.FLASH_CATEGORY,
                        page:       this.page
                    })
                        .then(() => {
                            this.flashPlanned = [];
                            Object.values(this.flashMessagesPlanned).forEach(message => {
                                message.attributes.contacts = [];
                                message.attributes.audiences.forEach(phoneNumber => {
                                    message.attributes.contacts.push(this.allContacts.find(contact => contact.phoneNumber === phoneNumber));
                                });
                                this.flashPlanned.push(message);
                            });

                            // Finish loading
                            this.loading = false;
                        });

                    // Get list of flash sent messages
                    this.indexSent({
                        'category': this.FLASH_CATEGORY,
                        page:       this.page
                    });
                });
        },

        /**
         * Add flash message
         */
        addFlashMessage () {
            this.resetSelectedFlashMessage();
            this.editMessage = false;
            this.$bvModal.show('flash-send-sidebar');
        },

        /**
         * Edit a selected flash message
         */
        editFlashMessage (flashMessage) {
            this.editMessage = true;
            this.selectFlashMessage({ id: flashMessage.id, status: 'planned' })
                .then(() => {
                    this.$bvModal.show('flash-send-sidebar');
                });
        },

        /**
         * Send immediately a flash message
         */
        sendFlashMessage (id) {
            Alert.scheduledMessageSendConfirmation('title-flash-message-send', 'confirm-scheduled-send')
                .then((response) => {
                    if (!response.isConfirmed) {
                        return;
                    }
                    this.send({ id })
                        .then(() => {
                            Alert.success(this.$i18n.t('scheduled-send-success'));
                        }).catch(() => {
                            Alert.fail(this.$i18n.t('default-error'));
                        });
                });
        },

        /**
         * Delete a selected flash message
         */
        deleteFlashMessage (id) {
            Alert.scheduledMessageDeleteConfirmation('title-flash-message-delete', 'confirm-flash-message-delete')
                .then((response) => {
                    if (!response.isConfirmed) {
                        return;
                    }

                    // Delete flash message
                    this.delete({ id }).then(() => {
                        this.loadFlashMessages();
                        Alert.success(this.$i18n.t('scheduled-deleted-success'));
                    }).catch(() => {
                        Alert.fail(this.$i18n.t('default-error'));
                    });
                });
        }
    }
};
</script>
